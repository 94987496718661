/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import CustomLink from './CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'
import ButtonMinimal from 'components/elements/ButtonMinimal'

const Content = styled.div`
  & ul {
    
    & li {
      
    }
  }

  & a {
    color: ${props => props.theme.color.text.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: underline;
  }

  & img {

  }

  p {
    line-height: 30px;
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.dark};
  }

  h3 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
    color: ${props => props.theme.color.text.dark};
    padding-top: 20px;
  }
`

const ParseContent = ({ content, className }) => {
  const {
    site
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if(site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if(!content) {
    return ''
  }

  return (
    <Content className={className}>
      {parse(content, {
        replace: domNode => {
          if (domNode.name === 'a' && domNode.attribs.class === 'custom-button') {
            return (
              <ButtonDefault to={domNode.attribs.href}>
                {domToReact(domNode.children, {})}
              </ButtonDefault>
            )
          }

          if (domNode.name === 'a' && domNode.attribs.class === 'button-minimal') {
            return (
              <ButtonMinimal to={domNode.attribs.href}>
                {domToReact(domNode.children, {})}
              </ButtonMinimal>
            )
          }

          if (domNode.name === 'img') {
            const attributes = domNode.attribs
      
            attributes.src = `${sourceUrl}${domNode.attribs.src}`
            attributes.className = attributes.class
      
            delete attributes.class
            delete attributes.srcset

            if(attributes.style) {
              delete attributes.style
            }
      
            return <img {...attributes} />
          }

          if(domNode.name === 'p') {
            domNode.children.forEach(child => {
              if (child.name === 'a') {
                child.attribs.target = '_blank'
              }
            })
          }
          
          if(domNode.name === 'a') {
            const attributes = domNode.attribs

            if(attributes.style) {
              delete attributes.style
            }

            if (attributes.href.indexOf('wp-content/') !== -1) {
              attributes.href = `https://admin.clearmind.nu${attributes.href}`
            }

            if(attributes.href.indexOf('www.') !== -1 || attributes.href.indexOf('admin.') !== -1 || attributes.href.indexOf('http:') !== -1 || attributes.href.indexOf('https:') !== -1) {
              return <a {...attributes} target="_blank">{domToReact(domNode.children, {})}</a>
            } 

            if(attributes.href.indexOf('tel:') !== -1 || attributes.href.indexOf('mailto:') !== -1) {
              return <a {...attributes}>{domToReact(domNode.children, {})}</a>
            }
      
            return (
              <CustomLink to={attributes.href}>
                {domToReact(domNode.children, {})}
              </CustomLink>
            )
          }
        },
      })}
    </Content>
  )
}

export default ParseContent