/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import ButtonMinimal from 'components/elements/ButtonMinimal'

// Images
import Calendar from 'img/icon/calendar.inline.svg'
import Eyeglasses from 'img/icon/eyeglasses.inline.svg'
import ArrowButton from 'img/icon/arrow_button.inline.svg'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'


const Blog = ({ posts }) => {
  return (
    <div className="row">
      {posts.map(({ node }) => (
        <div key={node.wordpress_id} className="col-lg-4 d-flex justify-content-center">
          <Post fields={node} />
        </div>
      ))}
    </div>
  )
}

const Post = ({ fields }) => {

const StyledNewsOverview = styled.div``

const Post = styled(motion.div)``

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const ImgContainer = styled.div`
  height: 200px;
  width: 300px;
`

const PostContent = styled(motion.div)`
  width: 300px;
  border-radius: 20px;
  padding: 30px;
`

const Description = styled.div`
  min-height: 90px;

  p {
    font-size: ${props => props.theme.font.size.s};
  }
`

const PostWrapper = styled.div`
  position: relative;
  top: -20px;
`

const InnerPost = styled.div``

const Button = styled(motion.button)`
  ${props => props.disabled && css`
    pointer-events: none;
    opacity: 0.1;
  `}

  ${props => props.back && css`
    transform: rotate(180deg);
  `}

  svg {
    width: 30px;
    height: 30px;
    /* path {
      fill: ${props => props.theme.color.face.border};
      stroke: ${props => props.theme.color.face.border};
    } */
  }
`
return (
  <Post 
  key={fields.wordpress_id} 
  className="col-lg-4 pb-5 d-flex justify-content-center"
  whileHover="hover"
  initial="init"
  // animate={isVisible || view ? "init" : "rest"}
  // variants={{
  //   hover: {},
  //   rest: {
  //     opacity: 0.4, y: 30
  //   },
  //   init: {
  //     opacity: 1, y: 0,
  //     transition: { delay: index * 0.2, type: 'spring', duration: .8 }
  //   }
  // }}
>
  <InnerPost>
    <div className="d-flex justify-content-center">
      <ImgContainer>
        <CustomLink to={fields.path}><StyledImg loading="eager" fadeIn={false} fluid={fields.acf.preview.image.localFile.childImageSharp.fluid} alt="" /></CustomLink>
      </ImgContainer>
    </div>
    <PostWrapper className="d-flex justify-content-center">
      <PostContent
        variants={{
          rest: { backgroundColor: 'transparent', },
          hover: {
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)',
            backgroundColor: '#ffffff',
            transition: {
              duration: 0.15,
              type: 'spring'
            }
          }
        }}
      >
        <div className="d-flex flex-wrap">
          {fields.categories.map(category => (
            <p className="font-size-xs mb-0 color-secondary mr-3 font-weight-xl" key={category.wordpress_id}>{category.name}</p>
          ))}
        </div>
        <div className="d-flex align-items-center pt-2">
          <p className="mb-0 mr-3 font-size-xs color-soft">
            <Calendar className="mr-1" />
            {fields.acf.preview.date}
          </p>
          <p className="mb-0 font-size-xs color-soft">
            <Eyeglasses className="mr-1" />
            {fields.acf.preview.readtime}
          </p>
        </div>
        <div className="pt-3">
          <h2 className="font-weight-xl font-size-ms">{parse(fields.title)}</h2>
          <Description>
            {parse(fields.acf.preview.small_description)}
          </Description>
        </div>
        <div className="d-flex pt-2 justify-content-end">
          <ButtonMinimal to={fields.path} icon="arrow">
            <p>Lees verder</p>
          </ButtonMinimal>
        </div>
      </PostContent>
    </PostWrapper>
  </InnerPost>
  </Post>
  )
}

export default Blog