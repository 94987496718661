/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Components
import ButtonShell from 'components/elements/ButtonShell'

// Images
import ArrowButton from 'img/icon/arrow_button.inline.svg'
// import UserButton from 'img/icon/user_button.inline.svg'
import userButton from 'img/icon/user_button.svg'

const StyledButton = styled(motion.div)`
  position: relative;
  background: transparent;
  border-radius: 29.5px;
  display: inline-block;
  height: 45px;
  z-index: 2;
  
  & > a, & > button {
    color: ${props => props.theme.color.text.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    padding: 10px 50px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;
    transition: .25s;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.text.secondary};
    }
  }
`

const IconWrapper = styled(motion.div)`
  position: absolute;
  top: 7px;
  pointer-events: none;
  height: 31px;
  width: 31px;

  img, svg {
    height: 100%;
    width: 100%;
  }
`

const ButtonMinimal = ({ icon, isAnchor, isCustom, to, children, className }) => (
  <StyledButton
    initial="rest"
    whileHover="hover"
    animate="rest"
    className={className}
  >
    {{
      'arrow': <IconWrapper variants={{ rest: { right: '8px' }, hover: { rotate: ['0deg', '20deg', '-20deg', '0deg'], transition: { duration: .3 } } }}><ArrowButton /></IconWrapper>,
      'user': <IconWrapper variants={{ rest: { right: '8px' }, hover: { rotate: ['0deg', '20deg', '-20deg', '0deg'], transition: { duration: .3 } } }}><img src={userButton} alt="" /></IconWrapper>
    }[icon]}
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonMinimal